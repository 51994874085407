import { Request } from 'express';

import fetch from 'common/utils/fetch';
import { sentryReactSend } from 'utils/sentry/client';
import { API } from 'config/constants/api';
import { AppStore } from './configure';

export const SET_CHINESE_SIGN = 'SET_CHINESE_SIGN';

export const getChineseSign = (
  date: string,
) => async (
  dispatch: AppStore['dispatch'],
  getState: AppStore['getState'],
  { req }: { req: Request },
) => {
  try {
    const redis = __SERVER__ ? req.redis : undefined;
    const url = `${API.chinese.date}${date}/`;
    const data = await fetch(url, 'GET', getState(), redis, undefined, undefined);

    if (!data) {
      const err = new Error(`${new Date()} Не удалось получить данные китайского знака по дате: ${url}`);
      throw err;
    }

    dispatch({
      type: SET_CHINESE_SIGN,
      data,
    });
  } catch (error) {
    sentryReactSend(error);
    // eslint-disable-next-line no-console
    console.error(`${new Date()} ${error}`);
  }
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer: ReducersTypes<'chinese'> = (state = { sign: '' }, action) => {
  const {
    type,
    data,
  } = action;

  switch (type) {
    case SET_CHINESE_SIGN:
      return {
        ...state,
        sign: data.sign,
      };
    default:
      return state;
  }
};

export default reducer;

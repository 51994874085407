/**
   * Разметка топ-100
   * @param isMobile - мобильная версия
   * @param top100Prefix - префик разметки
   * @param tail - хвост разметки
   * @returns объект полной разметки (префикс + хвот) исходя из версии (мобильная или нет)
   */

export const getTop100Markup = (
  isMobile: boolean,
  top100Prefix: string,
  tail?: string,
) => ({
  [`data-horo${isMobile ? '-mobile' : ''}-new`]: `${top100Prefix}${tail ? `::${tail}` : ''}`,
});

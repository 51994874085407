import fetch from 'common/utils/fetch';
import { sentryReactSend } from 'utils/sentry/client';
import { API } from 'config/constants/api';
import { AppStore } from './configure';

export const SET_LIKES_DATA = 'SET_LIKES_DATA';
export const SET_LIKE_DATA = 'SET_LIKE_DATA';

export const fetchLikesData = (
  codeNames: string,
) => async (
  dispatch: AppStore['dispatch'],
  getState: AppStore['getState'],
) => {
  try {
    const url = `${API.card.likes}?codenames=${codeNames}`;
    // Redis undefined т.к. эту ручку кешировать нельзя
    const data = await fetch(url, 'GET', getState());

    if (!data) {
      const err = new Error(`${new Date()} Не удалось получить данные лайков: ${url}`);
      throw err;
    }

    await dispatch({
      type: SET_LIKES_DATA,
      data,
    });
  } catch (error) {
    sentryReactSend(error);
    // eslint-disable-next-line no-console
    console.error(`${new Date()} ${error}`);
  }
};

export const postCardLike = (
  codename: string,
  hash: string,
) => async (
  dispatch: AppStore['dispatch'],
  getState: AppStore['getState'],
) => {
  try {
    const url = `${API.card.likes}add/`;
    // Redis undefined т.к. эту ручку кешировать нельзя
    const data = await fetch(url, 'POST', getState(), undefined, { codename, hash });

    if (!data) {
      const err = new Error(`${new Date()} Не удалось отправить данные лайков: ${url}`);
      throw err;
    }

    await dispatch({
      type: SET_LIKE_DATA,
      data,
    });
  } catch (error) {
    sentryReactSend(error);
    // eslint-disable-next-line no-console
    console.error(`${new Date()} ${error}`);
  }
};

export const deleteCardLike = (
  codename: string,
) => async (
  dispatch: AppStore['dispatch'],
  getState: AppStore['getState'],
) => {
  try {
    const url = `${API.card.likes}remove/`;
    // Redis undefined т.к. эту ручку кешировать нельзя
    const data = await fetch(url, 'POST', getState(), undefined, { codename });

    if (!data) {
      const err = new Error(`${new Date()} Не удалось удалить данные лайков: ${url}`);
      throw err;
    }

    await dispatch({
      type: SET_LIKE_DATA,
      data,
    });
  } catch (error) {
    sentryReactSend(error);
    // eslint-disable-next-line no-console
    console.error(`${new Date()} ${error}`);
  }
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer: ReducersTypes<'likes'> = (state = {}, action) => {
  switch (action.type) {
    case SET_LIKES_DATA:
      // eslint-disable-next-line no-case-declarations
      const structuredData = action.data && Array.isArray(action.data)
        ? action.data.reduce((result: LikesType, item: LikeType) => {
          // eslint-disable-next-line no-param-reassign
          result[item.codename] = item;
          return result;
        }, {})
        : {};

      return {
        ...state,
        ...structuredData,
      };
    case SET_LIKE_DATA:
      return {
        ...state,
        [action.data.codename]: action.data,
      };
    default:
      return state;
  }
};

export default reducer;

import { uniqueBy } from 'utils/uniqueBy';
import { Range, RangeType } from './range';

/**
 * Функция, которую принимает Array.prototype.filter
 */
type FilterFn = (mention: ExtendedMention) => boolean;

/**
 * Расширенное упоминание, содержащее диапазон замены в тексе и свой автотег
 */
export type ExtendedMention = MentionType & {
  tag: AutoTag,
  range: RangeType,
};

/**
 * Принимаем автотег и упоминание, возвращаем расширенное упоминание
 */
const createExtendedMention = (tag: AutoTag, mention: MentionType): ExtendedMention => ({
  ...mention,
  tag,
  range: Range.fromOffsetLength(mention.offset, mention.length),
});

/**
 * Принимаем массив автотегов, каждый тег содержит свои упоминания (one to many)
 * и возвращаем массив упоминаний, каждое из которых содержит тег. Добавляем
 * каждому упоминанию поле range (диапазон) - первый и последний индекс этого упоминания в тексте
 *
 * @param tags Автотеги
 */
const transformTagsToMentions = (tags: AutoTags): ExtendedMention[] => {
  // array of arrays of extended mentions
  const arrayOfMentions = tags.map(tag => {
    const { mentions } = tag;

    const extendedMentions = mentions.map(mention => {
      const extendedMention = createExtendedMention(tag, mention);
      return extendedMention;
    });

    return extendedMentions;
  });

  // flatten to array and return
  const mentions = arrayOfMentions.reduce((flatten, current) => flatten.concat(current), []);
  return mentions;
};

/**
 * Принимаем диапазоны и упоминания, возвращаем упоминания, не пересекающиеся с диапазонами
 *
 * @param ranges Диапазоны в которых нельзя упоминать
 * @param mentions Массив упоминаний
 */
const removeIntersectedWithRanges = (ranges: RangeType[], mentions: ExtendedMention[]) => (
  mentions.filter(mention => {
    const haveInterseciton = ranges.some(range => Range.isIntersect(range, mention.range));
    return !haveInterseciton;
  })
);

/**
 * Фильтруем упоминания, пересекающиеся друг с другом. Для этого принимаем отсортированный
 * массив упоминаний и проверяем, пересекается ли последнее добавленное упоминание и текущее, если
 * пересекается, фильтруем.
 *
 * @param sortedMentions отсортированный массив упоминаний
 */
const removeIntersectedWithEachOther = (sortedMentions: ExtendedMention[]) => {
  // Диапазон с которым точно ничего не пересекается
  const IMPOSSIBLE_MENTION_RANGE = { from: -1, to: -1 };

  // Чтобы при первой итерации сразу перезаписать на первое упоминание
  let prevIncludedMention = ({ range: IMPOSSIBLE_MENTION_RANGE });
  return sortedMentions.filter(m => {
    const shouldInclude = !Range.isIntersect(m.range, prevIncludedMention.range);
    if (shouldInclude) prevIncludedMention = m;
    return shouldInclude;
  });
};

type GetCorrectMentionsArgs = {
  tags: AutoTags
  rangesToExclude?: RangeType[]
  customFilter?: FilterFn
  onlyFirst?: boolean
};
type GetCorrectMentionsType = (args: GetCorrectMentionsArgs) => ExtendedMention[];

/**
 * Функция принимает тэги и возвращает непересекающиеся между собой упоминания тегов.
 * Каждое упоминание содержит ссылку на тэг (поле tag).
 *
 * @param tags Массив тэгов
 * @param rangesToExclude Опц. Массив диапазонов в которые нельзя вставлять упоминания
 * @param customFilter Опц. Функция фильтрации для дополнительной бизнес-логики
 * (например если понадобится не упоминать конкретные тэги с типом Organization)
 * @param onlyFirst = Опц. Если true упоминать тэг только 1 раз
 */
export const getCorrectMentions: GetCorrectMentionsType = ({
  tags,
  rangesToExclude = [],
  customFilter = null,
  onlyFirst = true,
}) => {
  // из массива тегов с массивами упоминаний получаем один большой массив упоминаний с полем tag
  let mentions = transformTagsToMentions(tags);

  // сортируем по возрастанию оффсетов
  mentions.sort((m1, m2) => m1.offset - m2.offset);

  // применяем кастомный фильтр если он есть
  if (customFilter) {
    mentions = mentions.filter(customFilter);
  }

  // исключаем упоминания пересекающиеся с каким-то массивом диапазонов, если он есть
  if (rangesToExclude.length) {
    mentions = removeIntersectedWithRanges(rangesToExclude, mentions);
  }

  // исключаем упоминания пересекающиеся между собой
  mentions = removeIntersectedWithEachOther(mentions);

  // оставляем только первое упоминание каждого тега, если onlyFirst true
  if (onlyFirst) {
    mentions = uniqueBy(mentions, m => m.tag.id);
  }

  return mentions;
};

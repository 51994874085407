import type { SignType, SignTypeChinese } from 'config/constants/sign';
import signNames from 'config/constants/signNames';

/**
 * Сформировать список ключ-значение для селекта со знаками зодиака / китайского
 * @returns массив объектов { name: 'name', value: 'value' }
 */
export const signNameValues = (source: 'zodiac' | 'chinese') => {
  const data = source === 'zodiac' ? signNames.zodiac : signNames.chinese;
  const onlySignNames = Object.keys(data).slice(1);

  return onlySignNames.map((key: SignType & SignTypeChinese) => ({
    name:  data[key],
    value: key,
  }));
};

/**
 * Сформировать список ключ-значение для селекта со знаками зодиака / китайского
 * @returns массив объектов { label: 'name', value: 'value' }
 */
export const signNameOptions = (source: 'zodiac' | 'chinese') => {
  const data = source === 'zodiac' ? signNames.zodiac : signNames.chinese;
  const onlySignNames = Object.keys(data).slice(1);

  return onlySignNames.map((key: SignType & SignTypeChinese) => ({
    label: data[key],
    value: key,
  }));
};

import { Request } from 'express';

import fetch from 'common/utils/fetch';
import { sentryReactSend } from 'utils/sentry/client';
import { API } from 'config/constants/api';
import { AppStore } from './configure';

export const GET_COMPATIBILITY_FORM_DATA = 'GET_COMPATIBILITY_FORM_DATA';

const initState: CompatibilityType = {};

export const fetchCompatibilityFormData = (
  man: string,
  woman: string,
) => async (
  dispatch: AppStore['dispatch'],
  getState: AppStore['getState'],
  { req }: { req: Request },
) => {
  try {
    const redis = __SERVER__ ? req.redis : undefined;
    const { currentPage } = getState().runtime;
    const url = `${currentPage}/${API.compatibility.form}woman-${woman}/man-${man}/`;
    const data = await fetch(url, 'GET', getState(), redis);

    if (!data) {
      const err = new Error(`${new Date()} Не удалось получить данные страницы: ${url}`);
      throw err;
    }

    dispatch({
      type: GET_COMPATIBILITY_FORM_DATA,
      data,
    });
  } catch (error) {
    sentryReactSend(error);
    // eslint-disable-next-line no-console
    console.error(`${new Date()} ${error}`);
  }
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer: ReducersTypes<'compatibility'> = (state = initState, action) => {
  switch (action.type) {
    case GET_COMPATIBILITY_FORM_DATA:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

export default reducer;

import { Request } from 'express';

import format from 'date-fns/format';
import fetch from 'common/utils/fetch';
import { sentryReactSend } from 'utils/sentry/client';

import { AppStore } from './configure';

export const GET_EMOJI_WIDGET_DATA = 'GET_EMOJI_WIDGET_DATA';

const initialState: EmoWidgetType = {
  data: {},
};

export const fetchEmoWidgetData = ({ sign }: { sign: string }) => async (
  dispatch: AppStore['dispatch'],
  getState: AppStore['getState'],
  { req }: { req: Request },
) => {
  try {
    if (!sign) {
      const err = new Error('Нужно передать знак зодиака для эмо-гороскопа');
      throw err;
    }

    const redis = __SERVER__ ? req.redis : undefined;
    const date = format(new Date(), 'yyyy-MM-dd');
    const url = `horoscopes/emotional/?sign=${sign}&date=${date}`;

    const data = await fetch(url, 'GET', getState(), redis, undefined, undefined, 'v4');

    if (data.error) {
      const err = new Error('Не удалось получить данные эмоционального гороскопа');
      throw err;
    }

    dispatch({
      type: GET_EMOJI_WIDGET_DATA,
      data: {
        ...data,
        sign,
        date,
      },
    });
  } catch (error) {
    sentryReactSend(error);
    // eslint-disable-next-line no-console
    console.error(`${new Date()} ${error}`);
  }
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer: ReducersTypes<'emojiWidget'> = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMOJI_WIDGET_DATA:
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
};

export default reducer;

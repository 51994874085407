import { Request } from 'express';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';

import fetch from 'common/utils/fetch';
import { sentryReactSend } from 'utils/sentry/client';

import {
  DATE_FORMAT,
} from 'config/constants/calendar';

import { AppStore } from './configure';

export const GET_CALENDAR_DATA = 'GET_CALENDAR_DATA';
export const SET_CALENDAR_DATA = 'SET_CALENDAR_DATA';

export const getCalendarData = (
  type: string,
  start: Date,
  end: Date,
) => async (
  dispatch: AppStore['dispatch'],
  getState: AppStore['getState'],
  { req }: { req: Request },
) => {
  try {
    const calendarData = getState().calendar;
    const middleDate = format(addDays(start, 15), DATE_FORMAT);
    if (calendarData && calendarData[middleDate] && calendarData[middleDate][type]) return;

    const startDate = format(start, DATE_FORMAT);
    const endDate = format(end, DATE_FORMAT);

    const redis = __SERVER__ ? req.redis : undefined;
    const isNames = type === 'names';
    const url = isNames
      ? `${type}/calendar/${startDate}/${endDate}/`
      : `${type}/statuses/?date_from=${startDate}&date_to=${endDate}`;
    const data = await fetch(url, 'GET', getState(), redis, undefined, undefined, isNames ? 'v3' : 'v4');

    if (!data) {
      const err = new Error(`${new Date()} Не удалось получить данные календаря: ${url}`);
      throw err;
    }

    dispatch({
      type:         GET_CALENDAR_DATA,
      calendarType: type,
      data,
    });
  } catch (error) {
    sentryReactSend(error);
    // eslint-disable-next-line no-console
    console.error(`${new Date()} ${error}`);
  }
};

export const setCalendarData = (
  type: string,
  data: any,
) => (
  dispatch: AppStore['dispatch'],
) => {
  dispatch({
    type:         SET_CALENDAR_DATA,
    calendarType: type,
    data,
  });
};

// todo: Перевести на новый вый вид типизации редьюсера
// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer: ReducersTypes<'calendar'> = (state = {}, action) => {
  const {
    type,
    data,
    calendarType,
  } = action;

  switch (type) {
    case GET_CALENDAR_DATA:
    case SET_CALENDAR_DATA: {
      return {
        ...state,
        ...data.reduce((a: CalendarType, b: CalendarType) => {
          const calendarValueName = calendarType === 'names' ? 'count' : 'statuses';
          const calendarValue = b[calendarValueName]
            ? {
              [calendarType]: b[calendarValueName],
            }
            : {};

          return {
            ...a,
            [b.date]: {
              ...calendarValue,
            },
          };
        }, {}),
      };
    }
    default:
      return state;
  }
};

export default reducer;

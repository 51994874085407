import {
  differenceInCalendarDays,
  eachDayOfInterval,
  format,
} from 'date-fns';

import { IBiorhythm } from 'Card';

type FormulaType = (
  daysFromDateOfBirth: number,
  type: IBiorhythm['type'],
) => number;

export const createBiorhythm = (
  dateOfBirth: Date,
  disableChart: boolean,
  startDate: Date,
  endDate: Date,
) => {
  const formula: FormulaType = (daysFromDateOfBirth, type) => {
    let numByType = 0;

    switch (type) {
      case 'physical':
        numByType = 23;
        break;
      case 'emotional':
        numByType = 28;
        break;
      case 'intellectual':
        numByType = 33;
        break;
      default:
        break;
    }

    const number = Math.sin((2 * Math.PI * daysFromDateOfBirth) / numByType) * 100;

    // Нормализация чтобы не было отрицательных значений + округление
    return Math.round((number + 100) / 2);
  };

  const getDayBiorhythm = (predictionDate: Date, index: number, centerDayIndex: number) => {
    const daysCount = differenceInCalendarDays(predictionDate, dateOfBirth);
    const physical = formula(daysCount, 'physical');
    const emotional = formula(daysCount, 'emotional');
    const intellectual = formula(daysCount, 'intellectual');

    return {
      date:            predictionDate,
      dateString:      format(predictionDate, 'yyyy-MM-dd'),
      physical,
      emotional,
      intellectual,
      isPredictionDay: index === centerDayIndex,
      middleShift:     index - centerDayIndex,
      order:           index + 1,
      isDisabled:      disableChart || daysCount < 0,
    };
  };

  const days = eachDayOfInterval({
    start: startDate,
    end:   endDate,
  });
  const daysLength = days.length;
  const centerDayIndex = daysLength % 2 === 0
    ? daysLength / 2
    : (daysLength - 1) / 2;

  return days.map((day, index) => getDayBiorhythm(day, index, centerDayIndex));
};

import { Request } from 'express';

import fetch from 'common/utils/fetch';
import { sentryReactSend } from 'utils/sentry/client';

import { AppStore } from './configure';

export const GET_VK_VIDEO_DATA = 'GET_VK_VIDEO_DATA';

const initialState: VkVideoWidgetType = {
  data: {
    text: '',
    sign: 'all',
  },
};

export const fetchVkVideoWidgetData = (sign?: string) => async (
  dispatch: AppStore['dispatch'],
  getState: AppStore['getState'],
  { req }: { req: Request },
) => {
  try {
    const redis = __SERVER__ ? req.redis : undefined;
    const url = `horoscopes/natal/?sign=${sign || 'all'}`;

    const data = await fetch(url, 'GET', getState(), redis, undefined, undefined, 'v4');

    if (data.error) {
      const err = new Error('Не удалось получить данные виджета VKВидео');
      throw err;
    }

    dispatch({
      type: GET_VK_VIDEO_DATA,
      data: {
        ...data,
        sign: sign || 'all',
      },
    });
  } catch (error) {
    sentryReactSend(error);
    // eslint-disable-next-line no-console
    console.error(`${new Date()} ${error}`);
  }
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer: ReducersTypes<'vkVideoWidget'> = (state = initialState, action) => {
  switch (action.type) {
    case GET_VK_VIDEO_DATA:
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
};

export default reducer;

import React from 'react';
import parser, { HTMLReactParserOptions, domToReact, Element } from 'html-react-parser';

import { Link } from 'react-router-dom';

import { safeGet } from 'utils/safeGet';
import { getTop100Markup } from 'common/utils/getTop100Markup';

export const htmlParser = (text: string, isMobile: boolean, top100Prefix: string) => {
  const options: HTMLReactParserOptions = {
    replace: (domNode: Element) => {
      if (domNode.type === 'tag' && domNode.name === 'a' && safeGet(() => domNode.attribs.href)) {
        const url = domNode.attribs.href;
        const isHoroUrl = url.includes('horoscopes.rambler.ru');
        const readyUrl = new URL(url);

        const getTop100 = getTop100Markup(isMobile, top100Prefix, `link::text_links::${isHoroUrl ? 'inner' : 'outer'}::${isHoroUrl ? url : readyUrl.pathname}`);

        let urlIsOuter = false;

        const outerUrls = [
          '/article',
        ];

        outerUrls.forEach(outerUrl => {
          if (readyUrl.pathname.includes(outerUrl)) {
            urlIsOuter = true;
          }
        });

        if (isHoroUrl && !urlIsOuter) {
          return (
            <Link
              to={readyUrl.pathname}
              {...getTop100}
            >
              {domToReact(domNode.children, options)}
            </Link>
          );
        }

        return (
          <a
            href={url}
            {...getTop100}
          >
            {domToReact(domNode.children, options)}
          </a>
        );
      }

      return domNode;
    },
  };

  const parsedText = parser(text, options);

  return parsedText;
};

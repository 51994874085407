export type ClusterBodyElemType = 'h1' | 'h2' | 'h3' | 'h4' | 'blockquote' | 'a';

/**
 * Диапазон
 *
 * @param from первый индекс диапазона
 * @param to последний индекс диапазона
 */
export type RangeType = {
  from: number;
  to: number;
};

// const createElemRegexp = (tag: HtmlElemType) => new RegExp(`<${tag}>(.|[\r\n])*?</${tag}>`);
/**
 * Регулярные выражения для матчинга тегов внутри тела кластера
 */
const ELEM_REGEXPS: Record<ClusterBodyElemType, RegExp> = {
  h1:         /<h1>(.|[\r\n])*?<\/h1>/,
  h2:         /<h2>(.|[\r\n])*?<\/h2>/,
  h3:         /<h3>(.|[\r\n])*?<\/h3>/,
  h4:         /<h4>(.|[\r\n])*?<\/h4>/,
  blockquote: /<blockquote>(.|[\r\n])*?<\/blockquote>/,
  a:          /<a[\s]+([^>]+)>((?:.(?!<\/a>))*.)<\/a>/,
};

/**
 * Принимает массив регекспов и возвращает новый объединяющий регексп с флагом /g.
 *
 * Например: [/regexp1/, /regexp2/, /regexp3/] => /(regexp1)|(regexp2)|(regexp3)/g
 *
 * @param regExps Массив регулярных выражений для объеденения
 */
const getUnionRegExp = (regExps: RegExp[]): RegExp => {
  const regExpString = regExps.map(regExp => `(${regExp.source})`).join('|');
  return new RegExp(regExpString, 'g');
};

/**
 * Создает диапазон из начала и длины.
 *
 * @param offset начало (первый индекс)
 * @param length длина диапазона
 */
const fromOffsetLength = (offset: number, length: number): RangeType => ({
  from: offset,
  to:   offset + length - 1,
});

/**
 * Получить массив диапазонов найденных регекспом областей строки
 */
const getRegExpRanges = (regExp: RegExp, str: string): RangeType[] => {
  const ranges: RangeType[] = [];
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const matched = regExp.exec(str);
    if (!matched) break;

    const range = fromOffsetLength(matched.index, matched[0].length);
    ranges.push(range);
  }
  return ranges;
};

/**
 * Принимает список тегов тела кластера и текст, возвращает массив диапазонов этих тегов в тексте
 */
const getElemsRanges = (elems: ClusterBodyElemType[], text: string) => {
  const tagsRegExps = elems.map(elem => ELEM_REGEXPS[elem]);
  const tagsUnionRegExp = getUnionRegExp(tagsRegExps);

  return getRegExpRanges(tagsUnionRegExp, text);
};

/**
 * Возвращает true если число @param n находится в диапазоне @param range
 */
const isInRange = (range: RangeType) => (n: number): boolean => {
  const { from, to } = range;
  return n >= from && n <= to;
};

/**
 * Возвращает true если два диапазона пересекаются
 */
const isIntersect = (rangeA: RangeType, rangeB: RangeType): boolean => {
  const isInRangeA = isInRange(rangeA);
  const isInRangeB = isInRange(rangeB);

  return (
    [rangeA.from, rangeA.to].some(isInRangeB)
    || [rangeB.from, rangeB.to].some(isInRangeA)
  );
};

export const Range = {
  fromOffsetLength,
  isInRange,
  isIntersect,
  getElemsRanges,
  getRegExpRanges,
};

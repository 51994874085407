import format from 'date-fns/format';

import {
  TIME_DISPLAY_FORMAT,
  CALENDAR_INIT_DATE,
} from 'config/constants/calendar';

/**
 * Функция проверки value в инпуте времени
 * @param val - строка в инпуте времени
 * @returns является ли время валидным
 */
export const checkTimeValue = (val?: string | null | undefined) => {
  const newVal = val || '';

  if (newVal !== '' && !/\d{1,2}:\d{2}/g.test(newVal)) {
    return false;
  }

  const [hours, minutes] = newVal.split(':');
  if (+hours > 23 || +minutes > 59) {
    return false;
  }

  return true;
};

/**
 * Строка для вывода времени
 * @param val  - строка в инпуте времен
 * @returns отформатированная строка
 */
export const getDisplayName = (val: string) => {
  if (val && /^\d{2}:\d{2}:\d{2}$/g.test(val)) {
    return format(new Date(`${CALENDAR_INIT_DATE}T${val}`), TIME_DISPLAY_FORMAT);
  } if (val && /^\d{1}:\d{2}$/g.test(val)) {
    return format(new Date(`${CALENDAR_INIT_DATE}T0${val}`), TIME_DISPLAY_FORMAT);
  } if (!val) {
    return '';
  }
  return val;
};

export const SET_META_DATA = 'SET_META_DATA';

const initialState: MetaType = {
  title:         '',
  description:   '',
  keywords:      '',
  og_image:      '',
  twitter_image: '',
  vk_image:      '',
  seo_text:      '',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer: ReducersTypes<'meta'> = (state = initialState, action) => {
  switch (action.type) {
    case SET_META_DATA:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

export default reducer;

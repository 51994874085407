import { safeGet } from 'utils/safeGet';

import {
  TAGS_LIST,
  NO_INCREMENT_TAGS_LIST,
  EMBEDS_CONFIG,
} from './config';

import { TextParagraph } from './components/TextParagraph';
import { TagParagraph } from './components/TagParagraph';
import { EmbedParagraph } from './components/EmbedParagraph';

/** Тип параграфа для рендера */
export enum PARAGRAPH_TYPE {
  TEXT,
  INCREMENT_TAG,
  NO_INCREMENT_TAG,
  EMBED,
}

/** Мапка получения компонента параграфа по его типу */
export const paragraphTypesList = {
  [PARAGRAPH_TYPE.TEXT]:             TextParagraph,
  [PARAGRAPH_TYPE.INCREMENT_TAG]:    TagParagraph,
  [PARAGRAPH_TYPE.NO_INCREMENT_TAG]: TagParagraph,
  [PARAGRAPH_TYPE.EMBED]:            EmbedParagraph,
};

/** Селектор для поиска вставленной в тело картинки */
const IMAGE_SELECTOR = 'article__image-wrapper';
/** Селектор для поиска вставленного в тело видео */
const VIDEO_SELECTOR = 'article__video-wrapper';

/**
  * Проверка параграфа на тегированность
  * @param text - текст параграфа
  */
const checkType = <T extends TagConfigType | EmbedConfigType>(
  text: string,
  config: T[],
): T | undefined => {
  const result = config.find(item => {
    const { flag } = item;
    if (text.search(flag) !== -1) {
      return true;
    }
    return false;
  });

  return result;
};

type getParagraphTypeType = (text: string) => ({
  type: PARAGRAPH_TYPE,
  config?: TagConfigType | EmbedConfigType,
  isMediaContent: boolean
});

/**
  * Получение типа параграфа и конфигурации работы с ним
  *
  * @param text - текст параграфа
  */
export const getParagraphType: getParagraphTypeType = (text: string) => {
  let config: (TagConfigType | EmbedConfigType) | undefined;
  let type = PARAGRAPH_TYPE.TEXT;
  const isMediaContent = safeGet(() => (
    text.includes(IMAGE_SELECTOR) || text.includes(VIDEO_SELECTOR)
  ), false);

  config = checkType(text, NO_INCREMENT_TAGS_LIST);
  if (config) {
    type = PARAGRAPH_TYPE.NO_INCREMENT_TAG;
  }

  if (!config) {
    config = checkType(text, TAGS_LIST);
    if (config) {
      type = PARAGRAPH_TYPE.INCREMENT_TAG;
    }
  }

  if (!config) {
    config = checkType(text, EMBEDS_CONFIG);
    if (config) {
      type = PARAGRAPH_TYPE.EMBED;
    }
  }

  return {
    type,
    config,
    isMediaContent,
  };
};

// Добавляет класс для плейсхолдера, пока фото/видео в тексте кластера не загрузятся
export const addMediaLoadedClass = (text: string) => text
  .replace('article__image-wrapper', 'article__image-wrapper--loading')
  .replace('article__video-wrapper', 'article__video-wrapper--loading');

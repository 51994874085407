import { Request } from 'express';

import fetch from 'common/utils/fetch';
import { sentryReactSend } from 'utils/sentry/client';
import { API } from 'config/constants/api';
import { AppStore } from './configure';

export const GET_SNIPPET_DATA = 'GET_SNIPPET_DATA';
export const SET_DEFAULT_SNIPPET_IMAGE = 'SET_DEFAULT_SNIPPET_IMAGE';

const initialState: FortuneType = {
  coffeeGameSnippet: {
    isSnippetReady:  false,
    isSnippetFailed: false,
    snippet:         null,
  },
};

type ReducerType = (
  state: any,
  action: {
    type: string
    data: any // todo: исправить
    name: string
    value: string
  }
) => RuntimeType;

export const getCoffeGameSnippet = (
  past?: string,
  present?: string,
  future?: string,
) => async (
  dispatch: AppStore['dispatch'],
  getState: AppStore['getState'],
  { req }: { req: Request },
) => {
  try {
    const redis = __SERVER__ ? req.redis : undefined;
    const url = `${API.fortune.snippet}?first=${past}&second=${present}&third=${future}`;
    const data = await fetch(url, 'GET', getState(), redis, undefined, undefined);

    if (!data) {
      const err = new Error(`${new Date()} Не удалось получить данные сниппета гадания по кофейной гуще: ${url}`);
      throw err;
    }

    dispatch({
      type: GET_SNIPPET_DATA,
      data,
    });
  } catch (error) {
    sentryReactSend(error);
    // eslint-disable-next-line no-console
    console.error(`${new Date()} ${error}`);
  }
};

export const setCoffeeGameSnippet = () => async (
  dispatch: AppStore['dispatch'],
) => {
  try {
    dispatch({
      type: SET_DEFAULT_SNIPPET_IMAGE,
    });
  } catch (error) {
    sentryReactSend(error);
    // eslint-disable-next-line no-console
    console.error(`${new Date()} ${error}`);
  }
};

// todo: Перевести на новый вый вид типизации редьюсера
// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer: ReducerType = (state = initialState, action) => {
  const {
    type,
    data,
  } = action;

  switch (type) {
    case GET_SNIPPET_DATA:
      return {
        ...state,
        coffeeGameSnippet: {
          isSnippetReady:  true,
          isSnippetFailed: false,
          snippet:         data.url,
        },
      };
    case SET_DEFAULT_SNIPPET_IMAGE:
      return {
        ...state,
        coffeeGameSnippet: {
          isSnippetReady:  false,
          isSnippetFailed: false,
          snippet:         null,
        },
      };
    default:
      return state;
  }
};

export default reducer;

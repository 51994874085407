import { isMatch, isWithinInterval } from 'date-fns';

import signDates from 'config/constants/signDates';

/**
 * Отдавать знак зодиака по дате рождения
 * @returns название знака (например, 'virgo')
 */
export const getSignByDate = (date: string) => {
  if (!isMatch(date, 'yyyy-MM-dd')) {
    return undefined;
  }

  const dateObject = new Date(date);
  const dateYear = dateObject.getFullYear();

  const signInterval = signDates.find(signDate => isWithinInterval(dateObject, {
    start: new Date(`${dateYear}-${signDate.start}`),
    end:   new Date(`${dateYear}-${signDate.end}`),
  }));

  if (!signInterval) return undefined;

  return signInterval.sign;
};

export const SET_ERROR = 'SET_ERROR';

const initialState: ErrorType = {
  status: 0,
  msg:    '',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer: ReducersTypes<'error'> = (state = initialState, action) => {
  const {
    type,
    vars,
  } = action;

  switch (type) {
    case SET_ERROR:
      return {
        ...state,
        ...vars,
      };
    default:
      return state;
  }
};

export default reducer;

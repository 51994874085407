const GEO_API_URL = 'https://www.rambler.ru/location/current';

export type GeoDataType = {
  id?: string,
  name?: string,
  parents?: string,
  timezone?: string,
  parent?: {
    id?: string,
    name?: string,
  },
};

export const fetchCurrentLocation = async (apiUrl = GEO_API_URL): Promise<GeoDataType> => {
  try {
    const res: Response = await fetch(
      apiUrl,
      { credentials: 'include' },
    );

    if (res.ok === false || res.status !== 200) {
      throw new Error(`Failed to get geo data (${res.status} ${res.statusText})`);
    }

    const data = await res.json();

    if (data.message) {
      throw new Error(data.message);
    }

    return data || {};
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Geo request: ${(e as { message: string }).message}`);
    return {};
  }
};

/* eslint-disable import/no-import-module-exports */
import {
  createStore, applyMiddleware, compose,
} from 'redux';
import { Request } from 'express';
import thunk, { ThunkMiddleware } from 'redux-thunk';

// https://webpack.js.org/migrate/5/#using-named-exports-from-json-modules
import packageInfo from '../../../package.json';

import reducers from './reducers';

export default function configureStore(
  initialState: IAppState,
  req?: Request,
) {
  if (__DEV__ && module.hot) {
    module.hot.accept('./reducers');
  }

  const thunkMiddleware = thunk.withExtraArgument({ req }) as ThunkMiddleware<
    {},
    Actions[keyof Actions],
    { req: typeof req }
  >;

  const initState = { ...initialState };

  if (typeof window !== 'undefined') {
    const getPWADisplayMode = () => {
      const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
      const { search } = window.location;

      if (search.includes('?twa') || document.referrer.startsWith('android-app://')) {
        return 'twa';
      }

      if (search.includes('?pwa')) {
        return 'pwa';
      }

      if (navigator.standalone || isStandalone) {
        return 'standalone';
      }

      return 'browser';
    };

    initState.runtime.PWADisplayMode = getPWADisplayMode();
  }

  if (__DEV__) {
    const createLogger = require('./logger').default; // eslint-disable-line global-require
    const loggerMiddleware = createLogger();

    // https://github.com/zalmoxisus/redux-devtools-extension#redux-devtools-extension
    // eslint-disable-next-line import/no-extraneous-dependencies, global-require
    const { composeWithDevTools } = require('redux-devtools-extension/developmentOnly');

    const composeEnhancers = composeWithDevTools({
      name: `${packageInfo.name}@${packageInfo.version}`,
    }) as typeof compose; // Типизация из модуля

    // Делать let enhancer: StoreEnhancer;
    // и общий return нельзя
    // Теряется типизация для redux-thunk
    return createStore(reducers, initState, composeEnhancers(
      applyMiddleware(thunkMiddleware, loggerMiddleware),
    ));
  }

  // Делать let enhancer: StoreEnhancer;
  // и общий return нельзя
  // Теряется типизация для redux-thunk
  return createStore(reducers, initState, applyMiddleware(thunkMiddleware));
}

export type AppStore = ReturnType<typeof configureStore>;

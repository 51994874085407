/** Тип параграфа для рендера */
export enum PARAGRAPH_TYPE {
  TEXT,
  INCREMENT_TAG,
  NO_INCREMENT_TAG,
  EMBED,
}

/** Тип тегированного параграфа */
export enum TAG_TYPE {
  BLOCKQOUTE,
  H1,
  H2,
  H3,
  H4,
}

/** Тип параграфа c эмбедом */
export enum EMBED_TYPE {
  INSTAGRAM,
  TWITTER,
  YOUTUBE,
  FACEBOOK,
  VK,
  VK_VIDEO,
  PLAYBUZZ,
  SKETCHFAB,
  EAGLEPLATFORM,
  GIPHY,
  QUIZ,
  GOOGLE_FORMS,
}

// Урлы эмбедов
const URL_TWITTER = 'https://platform.twitter.com/widgets.js';
const URL_INSTAGRAM = '//www.instagram.com/embed.js';
const URL_YOUTUBE = 'https://www.youtube.com/embed/';
const URL_FACEBOOK = 'https://www.facebook.com/plugins/post.php';
const URL_VK = '//vk.com/js/api/openapi.js';
const URL_VK_VIDEO = '//vk.com/video_ext.php';
const URL_PLAYBUZZ = 'https://embed.playbuzz.com/sdk.js';
const URL_SKETCHFAB = 'https://sketchfab.com/models';
const URL_EAGLEPLATFORM = '//ramblernews.media.eagleplatform.com';
const URL_GIPHY = 'https://giphy.com/embed';
const URL_QUIZ = 'https://quiz.rambler.ru/widget/sdk.js';
const URL_GOOGLE_FORMS = 'https://docs.google.com/forms';

const hReplase = (str: string) => str.slice(4, -5);

/** Мапка для работы с тегами */
export const TAGS_LIST: TagConfigType[] = [
  {
    flag:    '<blockquote>',
    type:    TAG_TYPE.BLOCKQOUTE,
    replace: (str: string) => str.slice(12, -13).trim().replace(/\n/g, '<br />'),
  },
];

/** Мапка для работы с не инкрементируемыми тегами */
export const NO_INCREMENT_TAGS_LIST: TagConfigType[] = [
  {
    flag:    '<h1>',
    type:    TAG_TYPE.H1,
    replace: hReplase,
  },
  {
    flag:    '<h2>',
    type:    TAG_TYPE.H2,
    replace: hReplase,
  },
  {
    flag:    '<h3>',
    type:    TAG_TYPE.H3,
    replace: hReplase,
  },
  {
    flag:    '<h4>',
    type:    TAG_TYPE.H4,
    replace: hReplase,
  },
];

/** Мапка для работы с эмбедами */
export const EMBEDS_CONFIG: EmbedConfigType[] = [
  {
    type:     EMBED_TYPE.INSTAGRAM,
    flag:     URL_INSTAGRAM,
    link:     URL_INSTAGRAM,
    onReload: () => {
      if (window.instgrm && window.instgrm.Embeds) {
        window.instgrm.Embeds.process();
      }
    },
  },
  {
    type:     EMBED_TYPE.TWITTER,
    flag:     URL_TWITTER,
    link:     URL_TWITTER,
    onReload: () => {
      if (window.twttr && window.twttr.widgets) {
        window.twttr.widgets.load();
      }
    },
  },
  {
    type: EMBED_TYPE.YOUTUBE,
    flag: URL_YOUTUBE,
    link: URL_YOUTUBE,
  },
  {
    type: EMBED_TYPE.FACEBOOK,
    flag: URL_FACEBOOK,
    link: URL_FACEBOOK,
  },
  {
    type: EMBED_TYPE.VK,
    flag: URL_VK,
    link: URL_VK,
  },
  {
    type: EMBED_TYPE.VK_VIDEO,
    flag: URL_VK_VIDEO,
    link: URL_VK_VIDEO,
  },
  {
    type: EMBED_TYPE.PLAYBUZZ,
    flag: URL_PLAYBUZZ,
    link: URL_PLAYBUZZ,
  },
  {
    type: EMBED_TYPE.SKETCHFAB,
    flag: URL_SKETCHFAB,
    link: URL_SKETCHFAB,
  },
  {
    type: EMBED_TYPE.EAGLEPLATFORM,
    flag: URL_EAGLEPLATFORM,
    link: URL_EAGLEPLATFORM,
  },
  {
    type: EMBED_TYPE.GIPHY,
    flag: URL_GIPHY,
    link: URL_GIPHY,
  },
  {
    type: EMBED_TYPE.QUIZ,
    flag: URL_QUIZ,
    link: URL_QUIZ,
  },
  {
    type: EMBED_TYPE.GOOGLE_FORMS,
    flag: URL_GOOGLE_FORMS,
    link: URL_GOOGLE_FORMS,
  },
];

export enum CLUSTER_TYPE {
  news = 0,
  photo = 1,
  video = 2,
  article = 3,
  test = 4,
}

/**
 * Статусы кластера
 * @param toRemove - Скрыт. 404. Кластер скрыт, так как ссылка на оригинал (источник)
 * @param new - Скрыт. 404. Только созданные
 * @param baked - Активен
 * @param unknown2 - Скрыт. Древний статус
 * @param unknown3 - Скрыт. Древний статус
 * @param trash - Скрыт. Древний статус
 * @param hidden - Скрыт в админке
 * @param censored - Скрыт. Цензура
 * @param uncensored - Активен
 * @param noFulltext - Скрыт. Неполнотекстовые кластера. (!) Отказываемся
 */
export enum CLUSTER_STATUS {
  toRemove = -1, // 404
  new = 0, // 404
  baked = 1, // 200
  unknown2 = 2, // 404
  unknown3 = 3, // 404
  trash = 4, // 404
  hidden = 5, // 410
  censored = 6, // 410
  uncensored = 7, // 200
  noFulltext = 8, // 410
}

type ClusterNameType = 'news' | 'photo' | 'video' | 'article' | 'test';

export const CLUSTER_NAME_TYPES: Record<CLUSTER_TYPE, ClusterNameType> = {
  [CLUSTER_TYPE.news]:    'news',
  [CLUSTER_TYPE.photo]:   'photo',
  [CLUSTER_TYPE.video]:   'video',
  [CLUSTER_TYPE.article]: 'article',
  [CLUSTER_TYPE.test]:    'test',
};

export enum AUTOTAG_TYPE {
  person = 'person',
  organization = 'organization',
  media = 'media',
  category = 'category',
  games = 'games',
  movie = 'movie',
  auto = 'auto',
  region = 'region',
  city = 'city',
  country = 'country',
}

export enum CLUSTER_TOPIC_TAG_ID {
  other = 209,
  ukraine = 191,
  usa = 189,
  newsMoscow = 176,
}

export enum CLUSTER_AUTO_TAG_ID {
  ukraine = 135640,
  usa = 135650,
}

export enum CLUSTER_TAG_ID_TYPE {
  tragedy = 130049,
  death = 150633,
}

import { Request } from 'express';
import isEmpty from 'lodash.isempty';

import fetch from 'common/utils/fetch';
import { sentryReactSend } from 'utils/sentry/client';
import { API } from 'config/constants/api';

import { AppStore } from './configure';

export const GET_TAROT_DATA = 'GET_TAROT_DATA';

const initialState: TarotType = {
  today: {},
};

export const fetchTarotData = (
  fetchName: keyof APIsUrls['tarotBlocks'],
) => async (
  dispatch: AppStore['dispatch'],
  getState: AppStore['getState'],
  { req }: { req: Request },
) => {
  try {
    const tarotData = getState().tarot[fetchName];
    if (!isEmpty(tarotData)) return;

    const redis = __SERVER__ ? req.redis : undefined;
    const url = `fortune/${API.tarotBlocks[fetchName]}`;
    const data = await fetch(url, 'GET', getState(), redis, undefined, undefined, 'v4');

    if (!data) {
      const err = new Error(`${new Date()} Не удалось получить данные блока таро: ${url}`);
      throw err;
    }

    dispatch({
      type: GET_TAROT_DATA,
      name: fetchName,
      data,
    });
  } catch (error) {
    sentryReactSend(error);
    // eslint-disable-next-line no-console
    console.error(`${new Date()} ${error}`);
  }
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer: ReducersTypes<'tarot'> = (state = initialState, action) => {
  switch (action.type) {
    case GET_TAROT_DATA:
      return {
        ...state,
        [action.name]: action.data,
      };
    default:
      return state;
  }
};

export default reducer;

import { AUTOTAG_TYPE } from 'common/utils/clusterContent/config';

import { Range, ClusterBodyElemType } from './range';
import { getCorrectMentions, ExtendedMention } from './getCorrectMentions';

const HUMAN_READABLE_TAG_TYPE: Record<string, string> = {
  [AUTOTAG_TYPE.organization]: 'organization',
  [AUTOTAG_TYPE.media]:        'media',
  [AUTOTAG_TYPE.person]:       'person',
  [AUTOTAG_TYPE.country]:      'region',
  [AUTOTAG_TYPE.region]:       'region',
  [AUTOTAG_TYPE.city]:         'region',
  [AUTOTAG_TYPE.auto]:         'auto',
  [AUTOTAG_TYPE.movie]:        'movie',
};

/**
 * Создает ссылку для тэга
 */
const getLinkHTML = (tagAlias: string, tagType: string, linkText: string): string => {
  const readableTagType = HUMAN_READABLE_TAG_TYPE[tagType];
  let href: string;
  if (readableTagType === 'region') {
    href = `/${tagAlias}/`;
  } else {
    href = `/${readableTagType}/${tagAlias}/`;
  }

  const className = `j-article-text-tag j-article-text-tag-${readableTagType}`;
  const dataBlocks = `click_link_contentpage_${readableTagType}`;
  const link = `<a href="${href}" class="${className} biqpf-autotag" data-blocks="${dataBlocks}" target="_blank">${linkText}</a>`;
  return link;
};

/**
 * Принимает текст и упоминания, возвращает протегированный текст
 */
const replaceMentions = (text: string, mentions: ExtendedMention[]) => {
  let textWithTags = text;
  let offsetChange = 0;
  mentions.forEach(mention => {
    const linkText = text.slice(mention.offset, mention.offset + mention.length);
    const { tag } = mention;
    const linkHTML = getLinkHTML(tag.alias, tag.autotag_type, linkText);

    // Replace
    const correctedOffset = mention.offset + offsetChange;
    const articleBeforeLink = textWithTags.slice(0, correctedOffset);
    const articleAfterLink = textWithTags.slice(correctedOffset + linkText.length);
    textWithTags = articleBeforeLink + linkHTML + articleAfterLink;

    // Modify offset to match new article length
    offsetChange += linkHTML.length - mention.length;
  });
  return textWithTags;
};

/**
 * Элементы тела кластера внутрь которых нельзя вставлять упоминания
 */
const DONT_INSERT_INSIDE_ELEMS: ClusterBodyElemType[] = [
  'a', 'h1', 'h2', 'h3', 'h4',
];

/*
 * Из-за наличия в тексте emoji автотеги приходят немного смещенными.
 * Методом проб было выявлено, что, вроде как, теггер считает эмодзи за символ длинной 1
 * Хотя на самом деле emoji могут быть разной длинны.
 * Была взята регулярка https://www.regextester.com/106421 и доработана. С ее помощью заменяем все emoji на символ пробела
 */
const EMOJI_REGEXP = /(\u00a9|\u00ae|[\u2000-\u2012]|[\u2020-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;
const EMOJI_REPLACE_SYMBOL = ' ';
const replaceEmoji = (text: string) => text.replace(EMOJI_REGEXP, EMOJI_REPLACE_SYMBOL);

/**
 * Принимает тело кластера и автотэги, возвращает протегированный текст
 *
 * @param onlyFirst Опц. если true упоминать один тэг только один раз, если false все разы
 * @param article тело кластера
 * @param tags автотеги
 */
export const getArticleWithTags = (
  article: ATCluster['body'],
  tags: AutoTags,
  onlyFirst: boolean = true,
) => {
  const filteredArticle = replaceEmoji(article);

  const clusterElemsRanges = Range.getElemsRanges(DONT_INSERT_INSIDE_ELEMS, filteredArticle);

  const mentions = getCorrectMentions({
    tags,
    onlyFirst,
    rangesToExclude: clusterElemsRanges,
    customFilter:    mention => {
      const inBody = mention.where === 'body';
      const notMedia = mention.tag.autotag_type !== AUTOTAG_TYPE.media;
      const notMovie = mention.tag.autotag_type !== AUTOTAG_TYPE.movie;
      return inBody && notMedia && notMovie;
    },
  });

  return replaceMentions(filteredArticle, mentions);
};

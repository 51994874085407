import { PARAGRAPH_TYPE } from 'common/utils/clusterContent/getParagraphType';
import { ParagraphConfigType } from 'common/utils/clusterContent/typings';

/**
  * Получение массивов параграфов для вставки до и внутрь CUT.
  * Механика -  CUT (укороченный) mobile
  * https://confluence.rambler-co.ru/pages/viewpage.action?pageId=48184350
  * @param paragraphsList - массив параграфов-строк
  */
export const cutFirstBanner = (paragraphsList: ParagraphConfigType[]): {
  paragraphsListBeforeCut: ParagraphConfigType[],
  paragraphsListInCut: ParagraphConfigType[],
} => {
  // Найден ли первый баннер
  let bannerInserted = false;
  // Вставлен ли параграф после первого баннера
  let paragraphAfterBanner = false;

  let paragraphsListBeforeCut: ParagraphConfigType[] = [];
  const paragraphsListInCut: ParagraphConfigType[] = [];

  if (paragraphsList.length > 2) {
    paragraphsList.forEach(config => {
      if (!bannerInserted || !paragraphAfterBanner) {
        paragraphsListBeforeCut.push(config);

        // Ищем параграф после баннера
        if (
          bannerInserted
          && !paragraphAfterBanner
          && config.type !== PARAGRAPH_TYPE.EMBED
          && config.type !== PARAGRAPH_TYPE.NO_INCREMENT_TAG
        ) {
          paragraphAfterBanner = true;
        }

        // Ищем первый баннер
        if (Boolean(config.bannerIndex) && !bannerInserted) bannerInserted = true;
      } else {
        paragraphsListInCut.push(config);
      }
    });
  } else {
    paragraphsListBeforeCut = [...paragraphsList];
  }

  return { paragraphsListBeforeCut, paragraphsListInCut };
};

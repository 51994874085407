import _pick from 'lodash.pick';

import { safeGet } from 'utils/safeGet';

/**
 * Получение iframe
 * @param entrie - объект события наблюдателя
 * @returns ссылку на iframe-элемент
 */
export const searchIframe = (target: Element) => safeGet(() => target.querySelector('iframe'));

/**
 * @param text - проверяемый на вставку видео через iframe текст
 *
 * @returns{boolean}
 */
export const checkIframeInText = (text: string) => (text.includes('iframe'));

// Puids, которые нужно передавать при вставке видео EaglePlatform
export const EAGLEPLATFORM_PUIDS: { [puid in string]: boolean} = {
  puid6:  true,
  puid15: true,
  puid18: true,
  puid48: true,
};

/**
 * Функция, возвращающая строку с пуидами для видео EaglePlatform
 * @param puids - рекламные пуиды
 * @param newPlayer - флаг, что пуиды нужны для нового плеера
 */
export const getPuidsStrForEaglePlatform = (
  puids: PuidsType,
  newPlayer: boolean = false,
): string | PuidsType => {
  const puidsArr: string[] = [];

  if (puids && newPlayer) {
    return _pick(puids, Object.keys(EAGLEPLATFORM_PUIDS)) as PuidsType;
  }

  if (puids) {
    Object.keys(puids).map(
      (puid: keyof PuidsType) => EAGLEPLATFORM_PUIDS[puid]
        && puidsArr.push(`"${puid}":"${puids[puid]}"`),
    );
    return puidsArr.length ? `&jparams={${encodeURIComponent(puidsArr.join(','))}}` : '';
  }

  return newPlayer ? { puid6: '' } : '';
};

/**
 * Функция преобразования iframe с видео
 * @param text - текст в котором есть видео iframe
 * @param adTemplateId - ID для добавления к видео iframe в виде get-параметра
 * @param puids - рекламные пуиды
 * @returns - текст с проставленным ad_template_id и puids в видео iframe
 */
export const changeVideoIframe = (text: string, adTemplateId: string, puids: PuidsType) => {
  const iframeSrc = text.match(/src\s*=\s*"(.+?)"/);

  const puidsStr = getPuidsStrForEaglePlatform(puids);
  const formattedText = `${adTemplateId}${puidsStr}`;

  return iframeSrc ? text.replace(iframeSrc[1], `${iframeSrc[1]}${formattedText}`) : text;
};

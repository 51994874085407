import { Request } from 'express';

import fetch from 'common/utils/fetch';
import { sentryReactSend } from 'utils/sentry/client';
import { API } from 'config/constants/api';

import { AppStore } from './configure';

export const GET_HAIR_STATUS_DATA = 'GET_HAIR_STATUS_DATA';
export const GET_MOON_DATA = 'GET_MOON_DATA';

const initialState: HeaderType = {
  status: '',
};

export const fetchHairData = (
  fetchName: keyof APIsUrls['header'],
) => async (
  dispatch: AppStore['dispatch'],
  getState: AppStore['getState'],
  { req }: { req: Request },
) => {
  try {
    const redis = __SERVER__ ? req.redis : undefined;
    const data = await fetch(API.header[fetchName], 'GET', getState(), redis, undefined, undefined, 'v4');

    if (!data) {
      const err = new Error(`${new Date()} Не удалось получить данные статуса стрижки для хедера`);
      throw err;
    }

    dispatch({
      type: GET_HAIR_STATUS_DATA,
      data,
    });
  } catch (error) {
    sentryReactSend(error);
    // eslint-disable-next-line no-console
    console.error(`${new Date()} ${error}`);
  }
};

export const fetchMoonData = (
  fetchName: keyof APIsUrls['header'],
) => async (
  dispatch: AppStore['dispatch'],
  getState: AppStore['getState'],
  { req }: { req: Request },
) => {
  try {
    const redis = __SERVER__ ? req.redis : undefined;
    const data = await fetch(API.header[fetchName], 'GET', getState(), redis, undefined, undefined, 'v4');

    if (!data) {
      const err = new Error(`${new Date()} Не удалось получить данные лунного дня для хедера`);
      throw err;
    }

    dispatch({
      type: GET_MOON_DATA,
      data,
    });
  } catch (error) {
    sentryReactSend(error);
    // eslint-disable-next-line no-console
    console.error(`${new Date()} ${error}`);
  }
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer: ReducersTypes<'header'> = (state = initialState, action) => {
  // Деструктурировать на этом уровне нельзя, так как рушится типизация
  switch (action.type) {
    case GET_HAIR_STATUS_DATA:
      return {
        ...state,
        ...action.data,
      };
    case GET_MOON_DATA: return {
      ...state,
      ...action.data,
    };
    default:
      return state;
  }
};

export default reducer;

import { Request } from 'express';
import isEmpty from 'lodash.isempty';
import HttpsProxyAgent from 'https-proxy-agent';
import fetch from 'node-fetch';

import config from 'config';

import {
  API_ENDPOINT_HUMAN_DESIGN_GEO,
  API_ENDPOINT_HUMAN_DESIGN,
} from 'config/constants/humanDesign';

import { safeGet } from 'utils/safeGet';
import promiseTimeout from 'utils/promiseTimeout';

type FetchOptions = {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE'
  headers: {
    'x-chain-id': string
    'accept'?: string
    'content-type'?: string
    'x-rsid'?: string
    'x-ruid'?: string
    'x-user-data'?: string
    'x-splits'?: string
    'x-request-id'?: string
  },
  body?: string
  rejectUnauthorized?: boolean
  agent?: any
};

type CSRConfig = {
  apiUrl: string
  apiTimeoutClient: number
};

export const request = async (
  path: string,
  opts: FetchOptions,
  csrConfig: CSRConfig,
  apiVersion: 'v3' | 'v4' | 'geo' | 'hd' | 'location',
) => {
  const apiTimeout = __SERVER__ ? config.API_TIMEOUT : csrConfig.apiTimeoutClient;
  let apiUrl = `${__SERVER__ ? config.API_URL : csrConfig.apiUrl}${apiVersion}/`;
  if (apiVersion === 'geo') {
    apiUrl = API_ENDPOINT_HUMAN_DESIGN_GEO;
  } else if (apiVersion === 'hd') {
    apiUrl = API_ENDPOINT_HUMAN_DESIGN;
  }

  const isMetric = path === '/ts-metrics/';

  const url = isMetric ? path : `${apiUrl}${path}`;

  try {
    // В RequestInit хедеры это Record<string, string>
    // Поэтому запись вида 'accept'?: string в FetchOptions['headers'] вызывает ошибку
    // Мол undefined не приводится к string
    // @ts-ignore
    const res = await promiseTimeout(apiTimeout || 0, fetch(url, opts as RequestInit), url);

    if (res.ok === false) {
      const err = new Error(`Не удалось получить данные запроса: ${url}`);

      // @ts-ignore
      err.status = res.status;
      throw err;
    }

    if (opts.method === 'DELETE' || isMetric) {
      return {};
    }

    const data = await res.json();

    return data;
  } catch (e) {
    // todo logger
    // eslint-disable-next-line no-console
    console.error(`${new Date()} ${e}`, opts);
    return {
      error:  (e && e.message) || `Ошибка запроса: ${url}`,
      status: safeGet(() => e.status, 500),
    };
  }
};

export default async (
  path: string,
  method: FetchOptions['method'],
  state: IAppState,
  redis?: Request['redis'],
  body?: object | [] | string,
  additionalHeaders?: {
    userData: object,
  },
  apiVersion?: 'v3' | 'v4' | 'geo' | 'hd',
): Promise<any> => {
  let cache = null;
  let data = null;
  const redisReady = redis && redis.status === 'ready' && process.env.REDIS_CACHE_API_REQ_ENABLED === 'true';

  const {
    ramblerId,
    rsid,
    ruid,
    requestId,
    config: {
      apiUrl,
      rejectUnauthorized,
      httpsProxy,
      apiTimeoutClient,
    },
    splits,
  } = state.runtime;

  const csrConfig: CSRConfig = { apiUrl, apiTimeoutClient };

  const opts: FetchOptions = {
    method,
    headers: {
      'x-chain-id':   ramblerId || '',
      'x-rsid':       rsid,
      'x-ruid':       ruid,
      'x-splits':     JSON.stringify(splits),
      'x-request-id': requestId,
    },
  };

  if (additionalHeaders && additionalHeaders.userData) {
    opts.headers['x-user-data'] = JSON.stringify(additionalHeaders.userData);
  }

  if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
    opts.headers['content-type'] = 'application/json';
    opts.body = typeof body === 'string'
      ? body
      : JSON.stringify(body);
  }

  if (__SERVER__ && (apiVersion === 'hd' || apiVersion === 'geo') && httpsProxy) {
    opts.rejectUnauthorized = rejectUnauthorized;
    opts.agent = new (HttpsProxyAgent as any)(httpsProxy);
  }

  if (redisReady) {
    try {
      cache = await promiseTimeout(config.REDIS_API_CACHE_TIMEOUT, redis!.get(path), path);
      cache = JSON.parse(cache);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      data = await request(path, opts, csrConfig, apiVersion || 'v3');

      return data;
    }
  }

  if (isEmpty(cache)) {
    data = await request(path, opts, csrConfig, apiVersion || 'v3');
    if (redisReady) redis!.set(path, JSON.stringify(data), 'EX', config.API_CACHE_TIME);

    return data;
  }
  return cache;
};

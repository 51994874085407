export const wordDeclination = (originalValue: number, type: string): string => {
  const value = originalValue % 100;
  const num = value % 10;

  if (value > 10 && value < 20) {
    return type === 'days' ? 'дней' : 'часов';
  }
  if (num > 1 && num < 5) {
    return type === 'days' ? 'дня' : 'часа';
  }
  if (num === 1) {
    return type === 'days' ? 'день' : 'час';
  }

  return type === 'days' ? 'дней' : 'часов';
};
